import logo from './logo.svg';
import './CountrySelect.css';
import { useState, useEffect } from 'react';



export const App = () => {
    let countresApi = [{id: 1, name: 'Філіппіни', selected: false}, {id: 2, name: 'Туретчина', selected: false}];
    let resortsApi = [{id: 4, name: 'Філіппіни Курорт - 1', country: 1, selected: true}, {id: 5, name: 'Туретчина Курорт - 1', country: 2, selected: true}, {id: 6, name: 'Туретчина Курорт - 2', country: 2, selected: true}, {id: 10, name: 'Філіппіни Курорт - 7', country: 1, selected: true},];

    const [searchInput, updateSearchInput] = useState('');
    const [countres, updateCountres] = useState(countresApi);
    const [resorts, updateResorts] = useState(resortsApi);
    const [viewAll, updateViewAll] = useState(false)
    const [resortBlockView, updateResortBlockView] = useState(false)
    const [blockView, updateBlockView] = useState(false)
    const [checkAllResorts, updateCheckAllResorts] = useState(true)
    const [inputView, updateInputView] = useState(true)
    const [inputInfo, updateInputInfo] = useState('')


    const checkedItem = ({target}) =>{
        console.log(target.checked)
    }

    

    const deleteDuble = (data) => Array.from(new Set(data))
    const searchItems = ({target}) => {
        updateBlockView(target.value.length > 0)
        updateResortBlockView(false)

        updateSearchInput(target.value);
        const searchResultName = (data) => data.filter((item) => item.name.toLowerCase().indexOf(target.value.toLowerCase()) !== -1);
        
  
        let sortCountreResult = searchResultName(countresApi);
        let sortResortResult = searchResultName(resortsApi);


        if(sortCountreResult.length > 0){
            updateViewAll(target.value.length > 0 && sortCountreResult.length > 0)
            sortCountreResult.forEach(({id}) => {
                sortResortResult = [...sortResortResult, ...resortsApi.filter(({country}) => country == id)]
            });
        }else if(sortResortResult.length > 0){
            updateViewAll(target.value.length > 0 && sortResortResult.length > 0)
            sortResortResult.forEach(({country}) => {
                sortCountreResult = [...sortCountreResult, ...countresApi.filter((item) => item.id == country)]
            });
            
        }

        updateCountres(deleteDuble(sortCountreResult));
        updateResorts(deleteDuble(sortResortResult));

    }


    const checkedCountry = (id) => {
        let sortCountreResult = countresApi.map((item) => item.id == id ? {...item, selected: !item.selected} : item);
        let sortResortResult = [];

        sortCountreResult.filter(({selected}) => selected).forEach(({id}) => {
            sortResortResult = [...sortResortResult, ...resortsApi.filter(({country}) => country == id)]
        });

        updateCountres(sortCountreResult);
        updateResorts(sortResortResult);
        updateResortBlockView(sortResortResult.length > 0)
        let country = sortCountreResult.filter(({selected}) => selected)[0]
        console.log(country)
        updateInputView(false)
        updateInputInfo(`${country.name}: курортів ${resorts.length}`)
        
    }

    const checkedAllResorts = () => {
        updateResorts(resorts.map((item) => ({...item, selected: !checkAllResorts})))
        updateCheckAllResorts(!checkAllResorts)
    }

    const checkedResort = (id) => {
        // let sortCountreResult = countres.map((item) => item.id == id ? {...item, selected: !item.selected} : item);
        updateCheckAllResorts(false)
        updateResorts(resorts.map((item) => item.id == id ? {...item, selected: !item.selected} : item))
    }

    const focusInput = () =>{
        updateBlockView(!blockView)
        updateInputView(true)
        updateBlockView(true)
    }

    const blurInput = () =>{
        if(resortBlockView){
            let country = countres.filter(({selected}) => selected)[0]
            updateInputView(false)
            updateInputInfo(`${country.name}: курортів ${resorts.length}`)
        }
        
        
    }


    const sendData = ()=>{
        const result = {
            country: countres.filter(({selected}) => selected)[0],
            resorts: resorts.filter(({selected}) => selected),
        }
        console.log('send Data', result)
    }

    const sendOneResort = (id)=>{
        let selectResort = resorts.filter((item) => item.id == id)
        const result = {
            country: countres.filter((item) => item.id == selectResort[0].country)[0],
            resorts: selectResort,
        }
        console.log('send Data', result)
    }

    const useResize = () => {
        const [size, setSize] = useState([0, 0]);
        useEffect(() => {
          const getSize = () => 
            setSize([window.innerWidth, window.innerHeight]);
          getSize();
          window.addEventListener("resize", getSize);
          return () => window.removeEventListener("resize", getSize);
        }, []);
          return size;
      };

    let size = useResize();

    return (
        <div className="search">
            <div className="search_title">Пошук туру</div>
            <div className="search_title-mobile">Куди</div>
            <div className="search_row">
                <div className="search_selected">
                    <div className="search_input">
                        {
                            inputView ? 
                            <input className='inputSearch' name="age" type="text" placeholder='Країна, курорт, готель' value={searchInput} onBlur={blurInput}  onFocus={focusInput} onChange={searchItems}/>
                            : <div className='inputSearch' onClick={focusInput} >{inputInfo}</div>
                        }
                        
                        
                        
                        <div className='open_search' onClick={()=>{updateBlockView(!blockView)}}>▽</div>
                    </div>
                    <div className='row-modal'>
                        {
                            blockView && 
                            <div className="search_select">
                            <div className={`search_list ${size[0] < 480 && resortBlockView ? `search_list-hide` : ``}`}>
                                {viewAll && <div className='search_list-title'>Країна</div>}
                                <>
                                    {
                                        countres.map(({id, name, selected})=> (
                                            <div onClick={()=>{checkedCountry(id)}} style={{cursor: 'pointer', background: selected ? `gray` : 'transparent', color: selected ? `white` : 'black'}}>
                                                {/* {!viewAll && <span><input type="checkbox" onChange={()=>{}} checked={selected} /></span>} */}
                                                <span>{name}</span>
                                            </div>
                                        ))
                                    }
                                </>
                                {
                                    (viewAll && resortBlockView !== true) && 
                                    <>
                                    {resorts.length > 0 && <div className='search_list-title'>Курорт</div>}
                                    {
                                        resorts.map(({id, name, selected})=> (
                                            <div onClick={()=>{sendOneResort(id)}} style={{cursor: 'pointer'}}>
                                                {!viewAll && <span><input type="checkbox" checked={selected} onChange={checkedItem} /></span>}
                                                <span>{name}</span>
                                            </div>
                                        ))
                                    }
                                    {/* {hotels.length > 0 && <div className='search_list-title'>Готель</div>}
                                    {
                                        
                                        hotels.map(({id, name, selected})=> (
                                            <div>
                                                {!viewAll && <span><input type="checkbox" checked={selected} onChange={checkedItem} /></span>}
                                                <span>{name}</span>
                                            </div>
                                        ))
                                    } */}
                                    </>
                                }
                            </div>
                            {
                                resortBlockView && 
                                <div className="search_list">
                                    <div onClick={checkedAllResorts}>
                                        <input type="checkbox" checked={checkAllResorts} onChange={checkedAllResorts} />
                                        <span>Всі курорти</span>
                                    </div>
                                    {
                                    
                                            resorts.map(({id, name, selected})=> (
                                                <div onClick={()=>{checkedResort(id)}}>
                                                    <span><input type="checkbox" checked={selected} onChange={checkedItem} /></span>
                                                    <span>{name}</span>
                                                </div>
                                            ))
                                    }
                                    <div className='row_button'>
                                        <div className='row_button-ok' onClick={sendData}>Застосувати</div>
                                    </div>
                                </div>
                            }
                        </div>
                        }
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default App;
